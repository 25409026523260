<!-- <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons"></link> -->
<!-- <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.min.css"></link> -->
<template>


    <div id="documentation">
        <el-tabs type="border-card">

            <el-tab-pane label="Documentation">

                <div>
                    <el-row class="tac">
                        <el-col :span="3" id="catalog">
                            <el-menu text-color='gray' class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose">
                                <el-menu-item index="1" @click="row_Introduction">Introduction</el-menu-item>
                                <el-menu-item index="2" @click="row_DCP">
                                    <span>Data Collection and Processing</span>
                                </el-menu-item>
                                <el-menu-item index="3" @click="row_function"><span>Functions of HUSCH</span></el-menu-item>
                                <el-menu-item index="3-1" @click="row_Ets"><span>&emsp;&emsp;Explore Specific Tissue</span>
                                </el-menu-item>
                                <el-menu-item index="3-2" @click="row_Edt"><span>&emsp;&emsp;Explore Specific Dataset</span>
                                </el-menu-item>
                                <el-menu-item index="3-2-1" @click="row_ov"><span>&emsp;&emsp;&emsp;&emsp;- Overview</span>
                                </el-menu-item>
                                <el-menu-item index="3-2-2" @click="row_ge"><span>&emsp;&emsp;&emsp;&emsp;- Gene Exploration</span>
                                </el-menu-item>
                                <el-menu-item index="3-2-3" @click="row_gsea"><span>&emsp;&emsp;&emsp;&emsp;- GSEA</span>
                                </el-menu-item>
                                <el-menu-item index="3-2-4" @click="row_cci"><span>&emsp;&emsp;&emsp;&emsp;- Cell-cell interaction</span>
                                </el-menu-item>
                                <el-menu-item index="3-2-5" @click="row_tf"><span>&emsp;&emsp;&emsp;&emsp;- TF</span></el-menu-item>
                                <el-menu-item index="3-2-6" @click="row_download"><span>&emsp;&emsp;&emsp;&emsp;- Download</span>
                                </el-menu-item>
                                <el-menu-item index="3-3" @click="row_Anno"><span>&emsp;&emsp;Annotation</span></el-menu-item>
                                <el-menu-item index="3-4" @click="row_cite"><span>&emsp;&emsp;How to cite HUSCH?</span></el-menu-item>
                            </el-menu>

                        </el-col>
                        <el-col :span="19">
                            <div id='doc'>
                                <h1 id="husch">HUSCH</h1><br>
                                <!-- <el-button @click="row">hi</el-button> -->
                                <h2 id="introduction">Introduction</h2><br>
                                <p>Human Universal Single Cell Hub (HUSCH) is a scRNA-seq database for analyzing and
                                    visualizing human gene expression in different tissues. The HUSCH database
                                    consists
                                    185 datasets from 45 human tissues covering 7 different platforms. HUSCH provides
                                    detailed cell-type annotation, expression visualization, marker gene identification,
                                    functional analyses, transcription factor, and cell-cell interaction analyses for
                                    each dataset, and also enables online cell-type annotation based on the curated
                                    reference.</p><br>
                                <h2 id="data-collection-and-processing">Data Collection and Processing</h2><br>
                                <p>We automatically crawled expression matrices and meta information of different human
                                    normal tissue scRNA-seq data from public databases including GEO, EBI, GSA,
                                    Single-cell Portal, and HCA.<br><br>

                                    For each dataset, a uniformed analysis pipeline
                                    <a class="doclink" href="https://github.com/liulab-dfci/MAESTRO"
                                        target="_blank">MAESTRO</a> was adopted to perform quality
                                    control, clustering, batch correction within the dataset, and automatic or original
                                    label-based cell type annotation. The annotated cell types were further harmonized
                                    using standard cell type names from Cell Ontology.<br><br>

                                    For each tissue, we integrated all the datasets within this tissue, integrated them,
                                    and performed batch correction within the tissue. Each tissue was classified into 4
                                    major lineages, including tissue-specific, stromal, endothelial, and immune. The
                                    cell types were further curated at the tissue level for potential mis-annotations.
                                    An integrated tissue object was derived for each tissue for further analyses.
                                </p><br><br>
                                <h2 id="function-of-husch">Functions of HUSCH</h2><br>
                                <p>HUSCH presents the analysis results in a user-friendly way for public access, which
                                    allows researchers to quickly gain insight into the expression of genes of interest
                                    at the single-cell level for certain tissues. HUSCH also provides an automatic
                                    online cell type annotation function using
                                    <a class="doclink" href="https://github.com/SELINA-team/SELINA.py"
                                        target="_blank">SELINA</a>, a deep learning-based framework
                                    for single-cell assignment with curated references.
                                </p>
                                <br>
                                <h3 id="explore-specific-tissue">Explore Specific Tissue</h3><br>
                                <p>On the home page, you can click the icon of the tissue you are interested in, and
                                    find the integrated datasets and the annotation results of that tissue. All
                                    integrated cells will be displayed using UMAP at the lineage level and cell type
                                    level. The UMAP of each lineage and cell type markers will be displayed individually
                                    below the UMAP of all cells.</p>
                                <br>


                                <img class="selinalogo" src="../assets/doc_home_inte.png"><br><br>

                                <p> A searching function for visualizing gene expression within that tissue is included
                                    on the tissue page. After inputting the gene symbol, a heatmap will be generated
                                    with the columns representing different datasets of this tissue, rows representing
                                    different cell types, and content including the gene expression level.</p>
                                <br>
                                <img class="selinalogo" id='doc_heatmap' src="../assets/doc_heatmap.png"><br><br>

                                <p>A summary table of this tissue will be shown below, which includes the basic
                                    information for all the datasets including the unique dataset id, the year of the
                                    study published, PMID of the study, sequencing platforms, cell number and etc. Each
                                    dataset could be clicked and linked to the specific dataset page.</p>
                                <br>
                                <img class="selinalogo" src="../assets/doc_table.png"><br><br>


                                <h3 id="explore-specific-dataset">Explore Specific Dataset</h3><br>
                                <p>You can explore specific dataset details in 5 modules, which are Overview, Gene,
                                    GSEA, CCI, TF, and download expression matrix in the Download module.</p><br>
                                <h4 id="ov"> Overview</h4> The Overview module includes 3 panels.

                                <p>- Clustering and annotation results of this dataset by UMAP plot.</p>
                                <p>- Proportion of each cell type for different patient or samples by pie chart.</p>
                                <p>- Top differential genes for each cell type in a table.</p><br>
                                <img class="selinalogo" src="../assets/doc_overview.png"><br><br>

                                <h4 id="ge"> Gene Exploration</h4>
                                <p>The Gene Exploration module includes 2 panels.</p>

                                <p><b> - Gene expression exploration:</b></p>

                                <p>You can enter your gene of interest into the search bar, the corresponding UMAP
                                    representing the input gene expression will be presented on the right by the feature
                                    plot.</p><br>

                                <p><b>- Comparison</b></p>
                                <p>You can compare the input gene expression in different cell types or clusters by
                                    violin plot. </p><br>
                                <img class="selinalogo" src="../assets/doc_gene.png"><br><br>

                                <h4 id="gsea">GSEA</h4>
                                <p>In this module, we provide the gene set enrichment analysis (GSEA)
                                    results for
                                    each
                                    dataset. In the GSEA tab, the pre-calculated GSEA results are available for users to
                                    characterize the functional differences between different cell types. We collected
                                    16,626
                                    gene sets from MSigDB, covering KEGG, hallmark. Heatmaps will be shown to display
                                    the
                                    enriched up-or-down-regulated pathways identified based on differential genes in
                                    each
                                    cluster.</p><br>
                                <img class="selinalogo" src="../assets/doc_gsea.png"><br><br>

                                <h4 id="cci">CCI</h4>
                                <p>In this module, we provide cell-cell interaction(CCI) powered by
                                    cellchat. We not only provide heatmaps and circle maps of overall interactions, you
                                    can also select specific cell types at the bottom panel.</p>
                                <br>
                                <img class="selinalogo" src="../assets/doc_cci.png"><br><br>

                                <h4 id="tf">TF Prediction</h4>

                                <p> In this module, we predict potential TF regulators with <a class="doclink"
                                        href="http://lisa.cistrome.org" target="_blank">LISA</a>
                                    , published
                                    by our lab. The results are shown in heatmap.</p><br>
                                <img class="selinalogo" src="../assets/doc_tf.png"><br><br>

                                <h4 id="download">Download</h4>
                                <p> You can download the expression matrix, meta information and downstream analysis results in
                                    this
                                    module.</p><br>
                                <h3 id="Anno">Annotation</h3><br>
                                <p>HUSCH provides cell-type annotation function powered by <a class="doclink"
                                        href="https://github.com/SELINA-team/SELINA.py" target="_blank">SELINA</a>, a
                                    deep
                                    learning-based
                                    framework for single cell assignment with multiple reference. You can query data of
                                    54
                                    tissue (including Blood, Brain, Lung, Liver, etc) with the three kinds of formats.
                                    You can
                                    also select <code>Single-cell</code> or <code>Cluster</code> level for user-specific
                                    annotation.<br>

                                <p>The annotation result will sent to your email after a while.</p>
                                </p><br>
                                <img class="selinalogo" src="../assets/doc_anno.png"><br><br>

                                <p>Before using this function, there are a few things to note:</p><br>
                                <p>SELINA supports 3 kinds input format: <code>plain</code>,<code>h5</code> and
                                    <code>mtx</code>.
                                    You can find
                                    detail information in <a class="doclink"
                                        href="https://selinapy.readthedocs.io/en/latest/prepare.html#preprocess-of-query-data"
                                        target="_blank">Preprocess-of-query-data</a>
                                    part.
                                </p>
                                <p>To guarantee your experience, we only allow uploading <code>.tar.gz</code> files for
                                    time
                                    saving. You
                                    need to compress the original file. Noted that the compressed file should have same
                                    prefix
                                    with the
                                    original file name(eg : <code>tar -zcvf Query.tar.gz Query</code>).</p><br>
                            <h3 id="cite">How to cite HUSCH?</h3><br>
                                <p>Xiaoying Shi, Zhiguang Yu, Pengfei Ren, Xin Dong, Xuanxin Ding, Jiaming Song, Jing Zhang, Taiwen Li, Chenfei Wang. HUSCH: an integrated single-cell transcriptome atlas for human tissue gene expression visualization and analyses. Nucleic Acids Research, Volume 51, Issue D1, 6 January 2023, Pages D1029-D1037, https://doi.org/10.1093/nar/gkac1001
                            <br><br>
                            </div>
                        </el-col>
                    </el-row>

                </div>
            </el-tab-pane>
            <el-tab-pane label="Cell Type">
                <el-row :gutter="20">
                    <el-col :span="10">
                        <el-card class="box-card">

                            <el-scrollbar>
                                <div>
                                    <el-tree ref="tree" accordion :data="treeData" highlight-current
                                        @node-click="getCurrentNode" @node-collapse="collapseNode"
                                        :render-content="renderContent">
                                        <span slot-scope="{ node, data }" class="custom-tree-node">
                                            <span>
                                                <i v-if="data.icon" :class="'tree-icon iconfont ' + data.icon" />
                                                <span class="tree-text">{{ node.label }}</span>
                                            </span>
                                        </span>
                                    </el-tree>
                                </div>

                            </el-scrollbar>
                        </el-card>
                    </el-col>

                </el-row>
            </el-tab-pane>
        </el-tabs>
    </div>

</template>

<script>
import axios from "axios";
import summary_celltype_l2 from "@/assets/summary_celltype_l2.json"; // 引用
// import summary_celltype_l3 from "@/assets/summary_celltype_l3.json"; // 引用
// import fab from 'vue-fab'
export default {
    data() {
        return {
            treeData: summary_celltype_l2,
            treeData3: [],
            tempList: [],
            tempList3: [],

            showtissue: false,
            tissuename: "",
            isShowlevel2: false,
            isShowlevel3: false,
            activeIndex: "1",

            options: [
                {
                    value: "Level2",
                    label: "Major",
                },
                {
                    value: "Level3",
                    label: "Minor",
                },
            ],
            value: "",
        };
    },
    created() {
        this.getTreeData();
        this.getTreeData3();
    },
    methods: {
        handleSelect(key, keyPath) {
            console.log(key, keyPath);
        },

        row_Introduction() {
            document.getElementById("introduction").scrollIntoView();
        },
        row_DCP() {
            document.getElementById("data-collection-and-processing").scrollIntoView();
        },
        row_function() {
            document.getElementById("function-of-husch").scrollIntoView();
        },
        row_Ets() {
            document.getElementById("explore-specific-tissue").scrollIntoView();
        },
        row_Edt() {
            document.getElementById("explore-specific-dataset").scrollIntoView();
        },
        row_ov() {
            document.getElementById("ov").scrollIntoView();
        },
        row_ge() {
            document.getElementById("ge").scrollIntoView();
        },
        row_gsea() {
            document.getElementById("gsea").scrollIntoView();
        },
        row_cci() {
            document.getElementById("cci").scrollIntoView();
        },
        row_tf() {
            document.getElementById("tf").scrollIntoView();
        },
        row_download() {
            document.getElementById("download").scrollIntoView();
        },
        row_Anno() {
            document.getElementById("Anno").scrollIntoView();
        },
        row_cite() {
            document.getElementById("cite").scrollIntoView();
        },
        selectThing(item) {
            if (item.value === "Level2") {
                //options中的value
                this.isShowlevel2 = true;
                this.isShowlevel3 = false;
            } else if (item.value === "Level3") {
                this.isShowlevel2 = false;
                this.isShowlevel3 = true;
            }
        },

        renderContent(h, { node, data, store }) {
            return (
                <span class="custom-tree-node">
                    <span>{node.label}</span>
                </span>
            );
        },

        getCurrentNode() {
            console.log(this.$refs.tree.getCurrentNode().label);
            this.tissuename = this.$refs.tree.getCurrentNode().label;
            //   console.log(tissuename);
            this.showtissue = true;
        },

        collapseNode() {
            this.showtissue = false;
        },

        async getTreeData() {
            this.tempList = summary_celltype_l2;
            const temp_list = [];

            for (var i = 0; i < this.tempList.length; i++) {
                const obj = {
                    label: this.tempList[i].Tissue,
                    id: i + 1,
                    children: [],
                };
                if (this.tempList[i].celltype.length >= 1) {
                    let temp = this.tempList[i].celltype.length;
                    while (temp > 0) {
                        const obj_child = {
                            label:
                                this.tempList[i].celltype[
                                this.tempList[i].celltype.length - temp
                                ],
                            id: (i + 1) * 100 + this.tempList[i].celltype.length - temp,
                        };
                        temp--;
                        obj.children.push(obj_child);
                    }
                }
                temp_list.push(obj);
            }
            this.treeData = temp_list;
        },

        async getTreeData3() {
            this.tempList3 = summary_celltype_l3;
            const temp_list3 = [];

            for (var i = 0; i < this.tempList3.length; i++) {
                const obj = {
                    label: this.tempList3[i].Tissue,
                    id: i + 1,
                    children: [],
                };
                if (this.tempList3[i].celltype.length >= 1) {
                    let temp = this.tempList3[i].celltype.length;
                    while (temp > 0) {
                        const obj_child = {
                            label:
                                this.tempList3[i].celltype[
                                this.tempList3[i].celltype.length - temp
                                ],
                            id: (i + 1) * 100 + this.tempList3[i].celltype.length - temp,
                        };
                        temp--;
                        obj.children.push(obj_child);
                    }
                }
                temp_list3.push(obj);
            }
            this.treeData3 = temp_list3;
        },
    },
};
</script>
    



<style lang="scss" scoped>
p {
    // line-height:20px;
    line-height: 1.5em;
    text-align: justify;
}

#catalog {
    font-size: 6px;

}


.el-menu-item {
    font-size: 12px;
    height: 44pX;
    line-height: 44px;

}

.el-tree {
    background: transparent;
    padding-top: 20px;
}

.custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
    background-color: blue;
}

.el-tree-node__label {
    font-size: 14px;
}

img {
    width: 90%;
    height: auto;
}

code {
    font-family: Consolas, "courier new";
    color: crimson;
    background-color: #f1f1f1;
    padding: 2px;
    font-size: 105%;
}

.doclink {
    color: rgb(26, 96, 67);
    font-weight: bold;
}

/* CSS link color (red) */
.doclink:hover {
    color: rgb(3, 138, 81);
}

#doc {
    margin-left: 5%;
    margin-right: 5%;
}

// .el-menu-item {
//    word-break: break-all;
//    white-space: break-spaces; 
// }

#doc_heatmap {
    width: 50%
}

.el-menu-item {
    display: flex;
    align-items: center;
    padding-right: 20px !important;
}

.el-menu-item span {
    white-space: normal;
    word-break: break-all;
    line-height: 20px;
    flex: 1;
}

</style>